import css from './index.module.sass'
import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../../Modal'
import Button from '../../Button'
import List from '../../List'
import Text from '../../Text'
import Link from '../../Link'

const TemplateTopicModal = (props) => {
  const handleClickSave = (event) => {
    event.preventDefault()
    if (props.onConfirm) props.onConfirm()
  }
  const handleClickCancel = (event) => {
    event.preventDefault()
    if (props.onCancel) props.onCancel()
  }
  return (
    <Modal
      containerRef={props.containerRef}
      onCenter={false}
      visible={props.visible}
      variant={'templateTopic'}
      loading={props.loading}
      noButtons
      header={
        <>
          <div className={css.Title}>
            <Text variant={'h3'} offset={'half-top'}>
              Add pitch topics
            </Text>
            <List variant="horizontal">
              <List.Item>
                <Link onClick={handleClickCancel}>Cancel</Link>
              </List.Item>

              <List.Item>
                <Button
                  disabled={
                    !(
                      props.templateTopicsSelected &&
                      props.templateTopicsSelected.length > 0
                    )
                  }
                  variant={props.variant === 'delete' ? 'red' : 'primary'}
                  onClick={handleClickSave}
                >
                  Save
                </Button>
              </List.Item>
            </List>
          </div>
        </>
      }
    >
      {props.content}
    </Modal>
  )
}

TemplateTopicModal.propTypes = {
  variant: PropTypes.string,
  loading: PropTypes.bool,
  content: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  templateTopicsSelected: PropTypes.array,
  containerRef: PropTypes.object,
  children: PropTypes.func,
}

export default TemplateTopicModal
