import css from './index.module.sass'

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useReducer,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { isIOS, isSafari } from 'react-device-detect'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Button from '../Button'
import Ionicon from '../Ionicon'
import Link from '../Link'
import Loader from '../Loader'
import Modal from '../Modal'
import Text from '../Text'
import VideoPlayer from '../VideoPlayer'
import TourTooltipContext from '../TourTooltipContext'
import Countdown from './Countdown'
import { useToggle, useOrientation } from 'react-use'

import {
  PRACTICE_TAPE2_TOOLTIP,
  TAPE2_TOOLTIP,
  TRY_AGAIN_TOOLTIP,
} from '../../constants/tooltips'
import { CREATE_VIDEOS_EDIT_PATH } from '../../constants/routes'
import Icon from '../Icon'
import Teleprompt from './Teleprompt'

const supportedBrowser = Boolean(window.MediaRecorder && navigator.mediaDevices)

const initialState = {
  ready: false,
  camera: supportedBrowser,
  countdown: false,
  recording: false,
  selectedTake: 0,
  takes: [],
  supportedBrowser: supportedBrowser,
  noCameraFound: false,
  lastTake: void 0,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'READY':
      return { ...state, ready: true }
    case 'COUNTDOWN_START':
      return { ...state, countdown: true }
    case 'COUNTDOWN_STOP':
      return { ...state, countdown: false }
    case 'RECORDING_START':
      return { ...state, countdown: false, recording: true }
    case 'RECORDING_STOP': {
      const nextTakes = [...state.takes]
      nextTakes[state.selectedTake] = action.blob

      return {
        ...state,
        camera: false,
        recording: false,
        ready: false,
        takes: nextTakes,
      }
    }
    case 'SELECT_TAKE': {
      const nextCamera = !state.takes[action.index]

      return {
        ...state,
        selectedTake: action.index,
        camera: nextCamera,
        ready: state.camera && nextCamera,
      }
    }
    case 'RETAKE': {
      const takes = state.takes.slice(0)
      takes[state.selectedTake] = null

      return { ...state, camera: true, takes }
    }
    case 'NO_CAMERA_FOUND':
      return { ...state, noCameraFound: true }
    default:
      return state
  }
}

const VideoRecorderMobile = ({
  id,
  title,
  question,
  teleprompt,
  lastTake,
  saving,
  saveButtonText,
  practice,
  renderButtons,
  renderPromptModal,
  onBeforeSave,
  onSave,
  isPaidUserSubscription,
  validTopics,
}) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [nextLocation, setNextLocation] = useState()
  const [showOrientationWarning, setShowOrientationWarning] = useState(false)
  const [deviceOrientation, setDeviceOrientation] = useState('')
  const [frontCamera, toggleFrontCamera] = useToggle(true)

  const { showTourTooltip, hideTourTooltip } = useContext(TourTooltipContext)

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    lastTake,
    ...(!practice &&
      lastTake && {
        takes: [lastTake],
        selectedTake: 1,
      }),
  })

  const orientation = useOrientation()
  const videoRef = useRef()
  const mediaRecorderRef = useRef()
  const timerIntervalRef = useRef()
  const retakeNumberRef = useRef(0)
  const closingStreamRef = useRef(0)
  const videoMetadata = useRef()

  const saved = state.lastTake !== lastTake

  useEffect(() => {
    return () => {
      hideTourTooltip()
    }
  }, [hideTourTooltip])

  useEffect(() => {
    if (state.recording || state.countdown) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [state.recording, state.countdown])

  useEffect(() => {
    if (!state.camera) {
      return
    }

    const video = videoRef.current
    let stream

    closingStreamRef.current = false

    const promise = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: { min: 1280, max: 1280 },
        height: { min: 720, max: 720 },
        aspectRatio: { exact: 16 / 9 },
        facingMode: frontCamera ? 'user' : 'environment',
      },
    })

    promise.then((_stream) => {
      if (closingStreamRef.current) {
        return _stream
      }

      stream = _stream
      const chunks = []

      video.srcObject = stream
      video.muted = true
      setCurrentTime(0)

      video.onloadedmetadata = () => {
        video.play()
      }

      video.oncanplay = () => {
        dispatch({ type: 'READY' })
      }

      const mediaRecorder =
        isIOS || isSafari
          ? new MediaRecorder(stream, {
              audioBitsPerSecond: 92000,
              videoBitsPerSecond: 1000000,
            })
          : new MediaRecorder(stream)

      mediaRecorder.onstart = () => {
        startTimer()

        dispatch({ type: 'RECORDING_START' })
        hideTourTooltip()
      }

      mediaRecorder.onstop = () => {
        clearInterval(timerIntervalRef.current)

        const blob = new Blob(chunks, {
          type: isIOS || isSafari ? 'video/mp4' : '',
        })
        blob.name = 'video.webm'
        video.srcObject = null
        video.muted = false

        dispatch({ type: 'RECORDING_STOP', blob })
      }

      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data)
      }

      mediaRecorderRef.current = mediaRecorder
    })

    promise.catch((error) => {
      dispatch({ type: 'NO_CAMERA_FOUND' })
      console.error(error) // eslint-disable-line no-console
    })

    return () => {
      if (!stream) {
        closingStreamRef.current = true

        promise.then((stream) => {
          if (closingStreamRef.current) {
            for (const track of stream.getTracks()) {
              track.stop()
            }
          }
        })
      } else {
        const mediaRecorder = mediaRecorderRef.current

        video.onloadedmetadata = null
        video.oncanplay = null
        mediaRecorder.onstart = null
        mediaRecorder.onstop = null
        mediaRecorder.ondataavailable = null

        for (const track of stream.getTracks()) {
          track.stop()
        }
      }
    }
  }, [state.camera, hideTourTooltip, frontCamera])

  useEffect(() => {
    if (deviceOrientation === '') {
      updateDeviceOrientation()
      window.addEventListener('orientationchange', function () {
        updateDeviceOrientation()
      })
    }

    const shouldShowOrientationWarning =
      deviceOrientation !== 0 && deviceOrientation !== 180
    setShowOrientationWarning(!shouldShowOrientationWarning)

    return () => {
      window.removeEventListener('orientationchange', function () {
        updateDeviceOrientation()
      })
    }
  }, [deviceOrientation])

  useEffect(() => {
    if (state.recording && orientation.type.startsWith('portrait')) {
      mediaRecorderRef.current.stop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation])

  const updateDeviceOrientation = () => {
    const orientation = isIOS
      ? window.orientation
      : window.screen.orientation.angle
    setDeviceOrientation(Math.abs(orientation))
  }

  const startCountdown = () => {
    dispatch({ type: 'COUNTDOWN_START' })
  }

  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setCurrentTime((time) => time + 1)
    }, 1000)
  }

  const handleCountdownEnd = () => {
    const mediaRecorder = mediaRecorderRef.current
    mediaRecorder.start()
  }

  const handleRetake = useCallback(() => {
    if (practice) {
      retakeNumberRef.current++

      if (retakeNumberRef.current === 2) {
        showTourTooltip(TRY_AGAIN_TOOLTIP)
      }
    }

    dispatch({ type: 'RETAKE' })
  }, [showTourTooltip, practice])

  const handleStart = useCallback(() => {
    startCountdown()
  }, [])

  const handleStop = useCallback(() => {
    if (state.countdown) {
      dispatch({ type: 'COUNTDOWN_STOP' })
    } else {
      showTourTooltip(practice ? PRACTICE_TAPE2_TOOLTIP : TAPE2_TOOLTIP)
      mediaRecorderRef.current.stop()
    }
    validTopics()
  }, [state.countdown, showTourTooltip, practice, validTopics])

  const setLoadedVideoMetadata = (metadata) => {
    videoMetadata.current = metadata
  }

  const handleSave = useCallback(() => {
    const take = state.takes[state.selectedTake]
    setNextLocation(false)
    onSave(take, videoMetadata.current)
  }, [onSave, state.selectedTake, state.takes])

  const formatTime = (seconds) => {
    return `0:${String(seconds).padStart(2, '0')}`
  }

  const currentTake = state.takes[state.selectedTake]

  if (!state.supportedBrowser || state.noCameraFound) {
    return (
      <div className={css.browserNotSupported}>
        <div className={css.browserNotSupportedInner}>
          <Icon
            name="exclamationMarkInACircle"
            style={{ color: '#1e2f3e', height: '24px', width: '24px' }}
          />

          <Text tag="p" variant="standardLarger" offset="single-top">
            {!state.supportedBrowser
              ? 'Your browser does not support video recording. Please use Chrome or Firefox to continue.'
              : 'There was an issue initializing your camera.' +
                ' To initiate the recorder, allow Pitchtape to access your camera and microphone. '}
          </Text>
        </div>
      </div>
    )
  }

  const renderEditLink = (editLinkProps) => {
    // This will disable edit once record starts
    if (!practice && !state.recording && !state.countdown) {
      return (
        <Link
          to={CREATE_VIDEOS_EDIT_PATH}
          params={{ topicId: id, subject: title }}
          variant="inherit"
          color="green"
          className={css.editLink}
          {...editLinkProps}
        />
      )
    }
  }

  return (
    <article className={css.container}>
      <div className={css.videoContainer}>
        {state.camera ? (
          <>
            {showOrientationWarning && (
              <div className={css.orientationWarning}>
                <div className={css.blurBackground} />
                <div className={css.warningText}>
                  <Text centered>
                    <Ionicon
                      name="refresh"
                      size="48"
                      color="blue"
                      style={{ marginRight: -10, marginLeft: -5 }}
                    />
                  </Text>
                  <br />
                  <Text variant="larger" centered>
                    Turn your phone horizontally
                    <br />
                    to record your pitch!
                  </Text>
                  <br />
                  <br />
                  <Text variant="standardLarger">
                    Please also make sure to:
                  </Text>
                  <div className={css.list}>
                    <div>
                      <div>
                        <Ionicon
                          name="checkSimple"
                          size="24"
                          color="blue"
                          style={{ marginRight: -10, marginLeft: -5 }}
                        />
                      </div>
                      <div>
                        Turn OFF your phone&apos;s Portrait Orientation Lock.
                      </div>
                    </div>
                    <div>
                      <div>
                        <Ionicon
                          name="checkSimple"
                          size="24"
                          color="blue"
                          style={{ marginRight: -10, marginLeft: -5 }}
                        />
                      </div>
                      <div>
                        Allow Pitchtape to use your camera and microphone.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <video
              className={
                state.camera && state.ready ? css.videoRecording : css.video
              }
              ref={videoRef}
              loop
              controlsList="nodownload"
              autoPlay
              muted
              playsInline
            />

            <div className={state.ready ? css.textWithOverlay : css.text}>
              <div>
                <Text tag="h1" variant="h3" weight="500" uppercase inline>
                  {title}
                </Text>{' '}
                {renderEditLink({
                  style: { verticalAlign: 2 },
                  children: 'Edit topic',
                })}
              </div>

              <Text tag="h1" variant="h3" weight="500">
                {question}
              </Text>

              {(isPaidUserSubscription || practice) && (
                <Teleprompt
                  topicId={id}
                  subject={title}
                  defaultTeleprompt={teleprompt}
                  example={'Enter your script here.'}
                  editable={!practice && !state.recording && !state.countdown}
                />
              )}
              {!state.recording && (
                <Button
                  className={css.positionCamera}
                  variant="primary"
                  onClick={toggleFrontCamera}
                  icon={
                    <Ionicon
                      color="white"
                      name="reverse_camera"
                      size="32"
                      style={{ marginRight: -15, marginLeft: 0 }}
                    />
                  }
                />
              )}
            </div>
          </>
        ) : (
          <div className={css.player}>
            <VideoPlayer
              {...(currentTake.binary
                ? {
                    binary: currentTake.binary,
                  }
                : {
                    binary: currentTake,
                  })}
              onLoadedVideoMetadata={setLoadedVideoMetadata}
            />
          </div>
        )}

        {state.countdown && <Countdown onEnd={handleCountdownEnd} />}

        {state.camera && !state.ready && (
          <div className={css.initializing}>
            <Loader
              variant="centered"
              icon={<Ionicon name="videocam" size="32" color="deepBlue" />}
            />
          </div>
        )}

        {state.recording && (
          <div className={css.time}>
            <Text color="white">
              Tape {state.selectedTake + 1}
              <i className={css.timeDot} />
              {formatTime(currentTime)}
            </Text>
          </div>
        )}

        {(state.recording || state.countdown || state.camera) && (
          <div className={css.btnRecord}>
            {state.recording || state.countdown ? (
              <Button variant="recordStop" onClick={handleStop}>
                <Text variant="standardLarger" weight="500" color="white">
                  Stop
                </Text>
              </Button>
            ) : (
              <Button
                variant="record"
                onClick={handleStart}
                disabled={!state.ready}
              >
                <Text variant="standardLarger" weight="500" color="white">
                  Start
                </Text>
              </Button>
            )}
          </div>
        )}
      </div>

      <div className={css.footer}>
        {!state.camera && (
          <div className={css.actions}>
            {!saving && (
              <Button variant="outline" onClick={handleRetake}>
                Try again
              </Button>
            )}

            {(!practice || saving) && (
              <Button
                variant="primary"
                offset="single-left"
                onClick={onBeforeSave || handleSave}
                disabled={saving}
              >
                <Text variant="button">
                  {saving ? (
                    <>
                      Saving
                      <AnimatedEllipsis />
                    </>
                  ) : (
                    saveButtonText || 'Save'
                  )}
                </Text>
              </Button>
            )}
          </div>
        )}
        {renderButtons &&
          state.camera &&
          !state.recording &&
          (!state.takes.length || state.lastTake) && (
            <div className={css.actions}>{renderButtons()}</div>
          )}
      </div>

      <Prompt
        message={(location) => {
          if (
            onSave &&
            !nextLocation &&
            !saved &&
            (state.takes.length === 2 || state.takes[0] !== state.lastTake)
          ) {
            setNextLocation(location)
            return false
          }

          return true
        }}
      />

      {nextLocation &&
        (renderPromptModal ? (
          renderPromptModal({ nextLocation, saving, onSave: handleSave })
        ) : (
          <Modal
            buttons={
              <>
                <Button variant="outline" to={nextLocation} disabled={saving}>
                  Yes
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setNextLocation(false)}
                  disabled={saving}
                >
                  No
                </Button>
              </>
            }
          >
            <Text tag="p" variant="h3">
              Are you sure you&apos;d like to exit the recording without saving?
            </Text>
          </Modal>
        ))}
    </article>
  )
}

VideoRecorderMobile.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  question: PropTypes.string,
  teleprompt: PropTypes.string,
  lastTake: PropTypes.object,
  saving: PropTypes.bool,
  saveButtonText: PropTypes.string,
  practice: PropTypes.bool,
  renderButtons: PropTypes.func,
  renderPromptModal: PropTypes.func,
  onBeforeSave: PropTypes.func,
  onSave: PropTypes.func,
  isPaidUserSubscription: PropTypes.bool,
  validTopics: PropTypes.func,
}

export default VideoRecorderMobile
