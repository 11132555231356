import css from './index.module.sass'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import React, { useState, useCallback, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Route, Switch } from 'react-router-dom'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Button from '../Button'
import Ionicon from '../Ionicon'
import Text from '../Text'
import VideoPlayer from '../VideoPlayer'
import VideoRecorder from '../VideoRecorder'
import Well from '../Well'

import EditForm from './EditForm'
import SlideForm from './SlideForm'
import Topics from './Topics'

import { VIDEO_FINISHED_STATUS } from '../../constants/videos'
import {
  CREATE_VIDEOS_EDIT_PATH,
  CREATE_VIDEOS_SLIDE_PATH,
  CREATE_VIDEOS_PATH,
  CREATE_VIDEOS_NEW_CUSTOM_PATH,
  CREATE_VIDEOS_GENERIC_PATH,
  BILLING_PATH,
  CREATE_PATH,
} from '../../constants/routes'

import { companyRequiresStitchVideos } from '../../helpers/companies'
import { injectParams } from '../../helpers/routes'
import Link from '../Link'

import TemplateTopicModal from './TemplateTopics/TemplateTopicModal'
import TemplateTopics from './TemplateTopics'
import { useMutation } from 'react-apollo'
import {
  CREATE_OR_UPDATE_COMPANY_VIDEO_TOPIC,
  GET_TEMPLATE_TOPICS,
  GET_USER_COMPANY,
  SELECT_TEMPLATE_TOPICS,
} from '../../constants/queries'
import Alert from '../Alert'
import { formatGraphQLError } from '../../helpers/errors'
import EditTopicModal from './Topics/EditTopicModal'
import CurrentUserContext from '../CurrentUserContext'
import Modal from '../Modal'
import { TOUR_VIDEO_BUILDER_STITCH_VIDEO } from '../../constants/tour'

const VideosRecorder = ({
  company,
  topics,
  topic,
  previousTopicUrl,
  nextTopicUrl,
  practice,
  saving,
  renderPromptModal,
  onBeforeSave,
  onSave,
  onStitch,
  history,
  refetchCompanyQuery,
  isFirstTime,
}) => {
  const {
    currentUser: { subscription, completedVideoBuilderTour },
  } = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription
    ? subscription.isPaidSubscription
    : false
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(
    localStorage.getItem('closeUpgradeMessageVideoBuilder') === null &&
      !isPaidUserSubscription
  )
  const [showUpgradeMessageCustomTopic, setShowUpgradeMessageCustomTopic] =
    useState(false)
  const [allTemplateTopicsInUse, setAllTemplateTopicsInUse] = useState(false)
  const [recording, setRecording] = useState(practice)
  const [newTopic, setNewTopic] = useState()
  // eslint-disable-next-line no-unused-vars
  const [creatingTopic, setCreatingTopic] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openTemplateModal, setOpenTemplateModal] = useState(false)
  const [openEditTopicModal, setOpenEditTopicModal] = useState(false)
  const [openCreateTopicModal, setOpenCreateTopicModal] = useState(false)
  const [selectedTemplateTopics, setSelectedTemplateTopics] = useState([])
  const [promptExitRecorder, setPromptExitRecorder] = useState()
  const [formData, setFormData] = useState({
    subject: topic ? topic.subject : '',
    question: topic ? topic.question : '',
    teleprompt: topic ? topic.teleprompt : '',
  })

  const isCustomTopic = useCallback(() => {
    return creatingTopic || (topic && topic.id && !topic.usingTemplate)
  }, [topic, creatingTopic])

  const saved =
    !creatingTopic &&
    topic &&
    topic.subject === formData.subject &&
    topic.question === formData.question &&
    topic.teleprompt === formData.teleprompt

  const [selectTemplateTopics, { error: selectError }] = useMutation(
    SELECT_TEMPLATE_TOPICS,
    {
      update: (
        cache,
        {
          data: {
            selectTemplateTopic: { ids },
          },
        }
      ) => {
        if (selectError) {
          return
        }
        if (ids && Array.isArray(ids) && ids.length > 0) {
          const { getTemplateTopics } = cache.readQuery({
            query: GET_TEMPLATE_TOPICS,
          })
          let mutableObjects = cloneDeep(getTemplateTopics)
          mutableObjects.forEach((template) => {
            if (ids.includes(template.id))
              Object.assign(template, { inUse: true })
          })
          cache.writeQuery({
            query: GET_TEMPLATE_TOPICS,
            data: { getTemplateTopics: mutableObjects },
          })
        }
      },
      refetchQueries: [{ query: GET_USER_COMPANY }],
      onCompleted: () => {
        setLoading(false)
        setOpenTemplateModal(false)
      },
    }
  )

  const [createOrUpdateVideoTopic, { loading: editing }] = useMutation(
    CREATE_OR_UPDATE_COMPANY_VIDEO_TOPIC,
    {
      errorPolicy: 'all',
      variables: {
        input: {
          id: topic && topic.id,
          ...formData,
        },
      },
      update: (cache, { data: { createOrUpdateCompanyVideoTopic } }) => {
        const newTopic = createOrUpdateCompanyVideoTopic.obj

        if (newTopic) {
          const { company } = cache.readQuery({ query: GET_USER_COMPANY })

          if (creatingTopic) {
            cache.writeQuery({
              query: GET_USER_COMPANY,
              data: {
                company: {
                  ...company,
                  videoTopics: [...company.videoTopics, newTopic],
                },
              },
            })
          } else {
            cache.writeQuery({
              query: GET_USER_COMPANY,
              data: {
                company: {
                  ...company,
                  videoTopics: company.videoTopics.map((videoTopic) =>
                    videoTopic.id !== newTopic.id ? videoTopic : newTopic
                  ),
                },
              },
            })
          }
        }
      },
      onCompleted: ({ createOrUpdateCompanyVideoTopic }) => {
        setLoading(false)
        const nextTopic = createOrUpdateCompanyVideoTopic.obj
        if (creatingTopic) {
          handleCancelCreateTopic()
        }
        if (nextTopic) {
          history.replace(
            injectParams(CREATE_VIDEOS_EDIT_PATH, {
              topicId: nextTopic.id,
              subject: nextTopic.subject,
            })
          )
        }
      },
    }
  )

  useEffect(() => {
    setRecording(practice)
  }, [topic, practice])

  const handleSave = useCallback(
    (take, metadata) => {
      const handleSaveCompleted = () => {
        if (nextTopicUrl) {
          history.push(nextTopicUrl)
        } else if (!practice) {
          setRecording(false)
        }
      }

      if (take.binary) {
        return handleSaveCompleted()
      }

      onSave({
        binary: take,
        topic: topic.id,
        metadata,
      }).then(() => handleSaveCompleted())
    },
    [history, nextTopicUrl, practice, topic, onSave]
  )

  const handleNewTopicRemove = useCallback(() => {
    if (!topic && topics.length) {
      const lastTopic = topics[topics.length - 1]
      history.replace(
        injectParams(CREATE_VIDEOS_EDIT_PATH, {
          topicId: lastTopic.id,
          subject: lastTopic.subject,
        })
      )
    }
    setNewTopic(void 0)
  }, [history, topic, topics])

  const handleEditCancel = useCallback(() => {
    history.replace(
      injectParams(CREATE_VIDEOS_PATH, {
        topicId: topic.id,
        subject: topic.subject,
      })
    )
  }, [history, topic])

  const handleSelectTemplate = useCallback(
    (data) => {
      setLoading(true)
      selectTemplateTopics({
        variables: {
          ids: data,
        },
      })
    },
    [selectTemplateTopics]
  )

  const handleSelectTopic = useCallback(() => {
    handleSelectTemplate(selectedTemplateTopics)
  }, [handleSelectTemplate, selectedTemplateTopics])

  const handleCancelSelectModal = () => {
    setOpenTemplateModal(false)
    // We clear the selection once modal is canceled or closed
    setSelectedTemplateTopics([])
  }

  const handleOpenSelectModal = () => {
    setOpenTemplateModal(true)
    setSelectedTemplateTopics([])
  }

  const handleSubmitForm = useCallback(() => {
    setLoading(true)
    createOrUpdateVideoTopic()
  }, [createOrUpdateVideoTopic])

  const handleEditTopic = useCallback(() => {
    handleSubmitForm()
  }, [handleSubmitForm])

  const handleCancelEditTopicModal = () => {
    setOpenEditTopicModal(false)
    history.replace(
      injectParams(CREATE_VIDEOS_PATH, {
        topicId: topic.id,
        subject: topic.subject,
      })
    )
  }

  const handleOpenEditTopicModal = useCallback(() => {
    if (!openEditTopicModal) {
      // Only change the form if modal is previously closed
      if (
        !isEqual(formData, {
          subject: topic ? topic.subject : '',
          question: topic ? topic.question : '',
          teleprompt: topic ? topic.teleprompt : '',
        })
      ) {
        setFormData({
          subject: topic ? topic.subject : '',
          question: topic ? topic.question : '',
          teleprompt: topic ? topic.teleprompt : '',
        })
      }
    }
    setOpenEditTopicModal(true)
  }, [topic, formData, openEditTopicModal])

  const handleOpenNewCustomTopicModal = useCallback(() => {
    if (!openCreateTopicModal) {
      setFormData({
        subject: '',
        question: '',
        teleprompt: '',
      })
    }
    setNewTopic(true)
    setCreatingTopic(true)
    setOpenCreateTopicModal(true)
  }, [openCreateTopicModal])

  const handleCreateTopic = () => {
    setNewTopic(false)
    setCreatingTopic(false)
    setOpenCreateTopicModal(false)
    handleSubmitForm()
  }

  const handleCloseUpgradeMessage = () => {
    setShowUpgradeMessage(false)
    localStorage.setItem('closeUpgradeMessageVideoBuilder', 'true')
  }

  const handleRemoveCallBack = () => {
    setAllTemplateTopicsInUse(false)
  }

  const handleNewCustomTopicCallBack = () => {
    setShowUpgradeMessageCustomTopic(true)
  }

  const renderPrevNextButtons = () => (
    <div className={css.prevNextButtons}>
      {previousTopicUrl && (
        <Button
          variant="outline"
          to={previousTopicUrl}
          icon={
            <Ionicon
              name="arrowDropleft"
              size="24"
              style={{ marginRight: -10, marginLeft: -5 }}
            />
          }
        >
          Previous
        </Button>
      )}

      {nextTopicUrl && (
        <Button
          variant="outline"
          to={nextTopicUrl}
          icon={
            <Ionicon
              name="arrowDropright"
              size="24"
              style={{ marginLeft: -10, marginRight: -5 }}
            />
          }
          iconPosition="after"
        >
          Next
        </Button>
      )}
    </div>
  )

  const handleCancelCreateTopic = () => {
    setCreatingTopic(false)
    setOpenCreateTopicModal(false)
    setNewTopic(false)
    history.replace(injectParams(CREATE_VIDEOS_GENERIC_PATH))
  }
  const handleReorderCallBack = () => {
    if (company?.concatenatedVideo) {
      refetchCompanyQuery()
    }
  }

  const renderStitchVideoSegmentsButton = () => {
    if (!practice)
      return (
        <>
          {company?.concatenatedVideo &&
          company?.concatenatedVideo.status !== VIDEO_FINISHED_STATUS ? (
            <Button variant="primary" disabled>
              Processing video
              <AnimatedEllipsis />
            </Button>
          ) : (
            <>
              <Button
                className={TOUR_VIDEO_BUILDER_STITCH_VIDEO}
                variant="primary"
                disabled={!companyRequiresStitchVideos(company)}
                onClick={onStitch}
              >
                Stitch video segments
              </Button>
            </>
          )}
        </>
      )
  }

  return (
    <>
      {showUpgradeMessageCustomTopic && (
        <div>
          <Alert
            variant="upgrade"
            centered
            textColor={'black'}
            onClose={() => {
              setShowUpgradeMessageCustomTopic(false)
            }}
          >
            <Link variant={'inherit'} to={BILLING_PATH}>
              Upgrade to add unlimited custom topics!
            </Link>
          </Alert>
        </div>
      )}
      {isPaidUserSubscription === false && showUpgradeMessage && (
        <Alert
          variant="upgrade"
          centered
          textColor={'black'}
          onClose={handleCloseUpgradeMessage}
        >
          Create a pitch video with topic templates for free!&nbsp;
          <Link variant={'inherit'} to={BILLING_PATH}>
            Upgrade for unlimited custom topics, teleprompter, pitch deck, and
            more.
          </Link>
        </Alert>
      )}
      {isPaidUserSubscription === false && allTemplateTopicsInUse && (
        <Alert variant="upgrade" centered textColor={'black'}>
          Maximum number of topics reached!&nbsp;
          <Link variant={'inherit'} to={BILLING_PATH}>
            Upgrade to add more topics.
          </Link>
        </Alert>
      )}
      <div className={css.btnExitContainer}>
        <div className={css.btnExit}>
          <Link
            to={CREATE_PATH}
            color="deepBlue"
            onClick={(evt) => {
              if (!practice && companyRequiresStitchVideos(company)) {
                evt.preventDefault()
                setPromptExitRecorder(true)
                return false
              }
            }}
          >
            <div className={css.exitLink}>
              <Text weight="500">
                Exit {practice ? 'Practice Mode' : 'Video Builder'}
              </Text>
            </div>
          </Link>
        </div>
      </div>

      <div className={css.container}>
        {selectError && (
          <Alert variant="error">{formatGraphQLError(selectError)}</Alert>
        )}
        {openTemplateModal && (
          <TemplateTopicModal
            content={
              <TemplateTopics
                selectedTopicsTemplates={selectedTemplateTopics}
                setSelectedTemplates={setSelectedTemplateTopics}
                handleSelect={handleSelectTemplate}
                handleCancel={handleCancelSelectModal}
                setAllTemplateTopicsInUse={setAllTemplateTopicsInUse}
              />
            }
            onConfirm={handleSelectTopic}
            onCancel={handleCancelSelectModal}
            loading={loading}
            visible={openTemplateModal}
            templateTopicsSelected={selectedTemplateTopics}
          />
        )}

        {openEditTopicModal && topic?.id && (
          <EditTopicModal
            loading={loading}
            onConfirm={handleEditTopic}
            onCancel={handleCancelEditTopicModal}
            content={
              <EditForm
                key={topic.id}
                topic={topic}
                setFormData={setFormData}
                formData={formData}
                submitForm={handleSubmitForm}
                isPaidUserSubscription={isPaidUserSubscription}
                isCustomTopic={isCustomTopic()}
              />
            }
            visible={openEditTopicModal}
            saved={saved}
            creating={creatingTopic}
            saving={editing}
            isPaidUserSubscription={isPaidUserSubscription}
            isCustomTopic={isCustomTopic()}
          />
        )}

        {openCreateTopicModal && (
          <EditTopicModal
            loading={loading}
            onConfirm={handleCreateTopic}
            onCancel={handleCancelCreateTopic}
            content={
              <>
                <EditForm
                  setFormData={setFormData}
                  formData={formData}
                  submitForm={handleSubmitForm}
                  isPaidUserSubscription={isPaidUserSubscription}
                  isCustomTopic={true}
                />
              </>
            }
            visible={openCreateTopicModal}
            creating={creatingTopic}
            saving={editing}
            isCustomTopic={true}
          />
        )}

        <Switch>
          <Route
            path={CREATE_VIDEOS_NEW_CUSTOM_PATH}
            render={() => handleOpenNewCustomTopicModal()}
          />
          <Route
            path={CREATE_VIDEOS_EDIT_PATH}
            render={() => handleOpenEditTopicModal()}
          />
          <Route
            path={CREATE_VIDEOS_SLIDE_PATH}
            render={() => (
              <SlideForm topic={topic} onCancel={handleEditCancel} />
            )}
          />
        </Switch>

        <div className={css.topicsAndKnowledgeCenter}>
          <aside className={css.topics}>
            <Well>
              {practice ? (
                <>
                  <Text variant="blockTitle" tag="h1" offset="half-bottom">
                    Record Practice Video
                  </Text>
                  <Text tag="p" variant="light" italic offset="double-bottom">
                    You can use this space to practice pitching on video by
                    answering the intro question below.
                  </Text>
                  <Text variant="blockTitle" tag="h2" weight="400">
                    Practice Topic:
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    variant="blockTitle"
                    tag="h1"
                    offset="single-top"
                    centered
                  >
                    My Pitch Video Outline
                  </Text>
                </>
              )}

              <Topics
                topics={topics}
                topic={topic}
                hasNewTopic={Boolean(newTopic)}
                practice={practice}
                onNewTopicRemove={handleNewTopicRemove}
                newTopic={handleOpenSelectModal}
                removeCallBack={handleRemoveCallBack}
                reorderCallBack={handleReorderCallBack}
                showUpgradeMessageCallBack={handleNewCustomTopicCallBack}
                showingUpgradeMessage={showUpgradeMessageCustomTopic}
                isFirstTime={isFirstTime}
              />

              {renderStitchVideoSegmentsButton()}
            </Well>
          </aside>

          {promptExitRecorder && (
            <Modal
              buttons={
                <>
                  {renderStitchVideoSegmentsButton()}
                  <Button
                    variant="outline"
                    onClick={() => {
                      setPromptExitRecorder(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => history.replace(injectParams(CREATE_PATH))}
                    disabled={saving}
                  >
                    Exit video builder
                  </Button>
                </>
              }
            >
              <Text tag="p" variant="h3">
                Are you sure you want to exit the Video Builder before stitching
                your video segments?
              </Text>
              <Text tag="p" offset="double-top" variant="standardLarger">
                <Ionicon
                  name="alert"
                  color="red"
                  size="24"
                  style={{ marginRight: '0.5em' }}
                />
                Any changes made to your video topics will not be updated in
                your Pitch Video until you click on the Stitch Video Segments
                button and your video has completed stitching. This may take up
                to 5 min.
              </Text>
            </Modal>
          )}
        </div>

        <div className={css.topicsAndRecorder}>
          <div className={css.recorder}>
            <Well padding="0">
              <div className={css.recorderInner}>
                <Switch>
                  <Route
                    render={() => {
                      if (!topic) {
                        return (
                          <div className={css.emptyState}>
                            <div className={css.emptyStateIcon}>
                              <Ionicon name="videocam" color="gray" size="48" />
                            </div>

                            <Text tag="h1" variant="blockTitle">
                              You don&apos;t have any topics to record yet
                            </Text>
                            <Text
                              tag="p"
                              variant="standard"
                              offset="single-top"
                              centered
                            >
                              Add a topic to record
                            </Text>
                            {/*<Link variant='iconOnLeft' offset='single-top' to='#'*/}
                            {/*      icon={*/}
                            {/*        <Icon name='pitchtapePlayPrimary' style={ { 'height': '12px', 'width': '12px' } }/> }>*/}
                            {/*  See how video builder works*/}
                            {/*</Link>*/}
                          </div>
                        )
                      }

                      const { video } = topic

                      // Since DEV-408 for existing users we should show the video builder tour so we enforce to the
                      // recorder
                      if (!video || recording || !completedVideoBuilderTour) {
                        return (
                          <VideoRecorder
                            key={topic.id}
                            id={topic.id}
                            title={topic.subject}
                            question={topic.question}
                            teleprompt={topic.teleprompt}
                            lastTake={video}
                            saving={saving}
                            saveButtonText={
                              practice ? 'I’m ready! Record my pitch' : 'Save'
                            }
                            practice={practice}
                            renderPromptModal={renderPromptModal}
                            renderButtons={renderPrevNextButtons}
                            onBeforeSave={onBeforeSave}
                            onSave={onSave && handleSave}
                            isPaidUserSubscription={isPaidUserSubscription}
                          />
                        )
                      }

                      return (
                        <>
                          <VideoPlayer
                            binary={video.binary}
                            deckPage={video.pitchdeckPage}
                          />

                          <div className={css.buttons}>
                            <Button
                              variant="outline"
                              onClick={() => setRecording(true)}
                            >
                              Retake
                            </Button>

                            {renderPrevNextButtons()}
                          </div>
                        </>
                      )
                    }}
                  />
                </Switch>
              </div>
            </Well>
          </div>
        </div>
      </div>
    </>
  )
}

VideosRecorder.propTypes = {
  company: PropTypes.object,
  topics: PropTypes.array,
  topic: PropTypes.object,
  path: PropTypes.string,
  previousTopicUrl: PropTypes.string,
  nextTopicUrl: PropTypes.string,
  practice: PropTypes.bool,
  saving: PropTypes.bool,
  renderPromptModal: PropTypes.func,
  onBeforeSave: PropTypes.func,
  onSave: PropTypes.func,
  onStitch: PropTypes.func,
  history: PropTypes.object,
  refetchCompanyQuery: PropTypes.func,
  isFirstTime: PropTypes.bool,
}

export default withRouter(VideosRecorder)
