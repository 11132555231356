import css from './VideoPreview.module.sass'

import React, { useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'react-apollo'
import PropTypes from 'prop-types'

import VideoNotReady from '../VideoNotReady'
import VideoPlayer from '../VideoPlayer'
import { VIDEO_FINISHED_STATUS } from '../../constants/videos'
import Well from '../Well'
import Button from '../Button'
import DevicesContext from '../DevicesContext'
import { CREATE_VIDEOS_PATH } from '../../constants/routes'
import { GET_USER_COMPANY } from '../../constants/queries'
import VideoPreviewHeader from './VideoPreviewHeader'

const POLLING_INTERVAL = 30000
const VideoPreview = ({ company }) => {
  const { mobile } = useContext(DevicesContext)
  const { concatenatedVideo } = company

  const {
    data: companyData,

    startPolling,
    stopPolling,
  } = useQuery(GET_USER_COMPANY, {
    fetchPolicy: 'network-only',
  })

  const status = useMemo(
    () =>
      companyData?.company?.concatenatedVideo?.status ||
      concatenatedVideo?.status,
    [companyData, concatenatedVideo]
  )

  useEffect(() => {
    if (status !== VIDEO_FINISHED_STATUS) {
      startPolling(POLLING_INTERVAL)
    }

    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling, status])

  return (
    <Well title="Pitch Video Preview">
      <div className={css.container}>
        {status === VIDEO_FINISHED_STATUS ? (
          <VideoPlayer
            header={
              !mobile && <VideoPreviewHeader company={company} editable />
            }
            topics={company.videoTopics}
            binary={concatenatedVideo.binary}
            hlsReady={false}
          />
        ) : (
          <VideoNotReady company={company} />
        )}
        {mobile && (
          <div className={css.buildButton}>
            <Button variant="primary" to={CREATE_VIDEOS_PATH}>
              Build my pitch
            </Button>
          </div>
        )}
      </div>
    </Well>
  )
}

VideoPreview.propTypes = {
  company: PropTypes.object,
}

export default VideoPreview
