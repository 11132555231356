import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Mobile from './Mobile'
import Desktop from './Desktop'
import DevicesContext from '../DevicesContext'

const VideosRecorder = ({
  company,
  topics,
  topic,
  path,
  previousTopicUrl,
  nextTopicUrl,
  practice,
  saving,
  renderPromptModal,
  onBeforeSave,
  onSave,
  onStitch,
  history,
  refetchCompanyQuery,
  isFirstTime,
}) => {
  const { mobileUa, tabletUa } = useContext(DevicesContext)
  const Component = mobileUa || tabletUa ? Mobile : Desktop

  return (
    <Component
      company={company}
      topics={topics}
      topic={topic}
      path={path}
      previousTopicUrl={previousTopicUrl}
      nextTopicUrl={nextTopicUrl}
      practice={practice}
      saving={saving}
      renderPromptModal={renderPromptModal}
      onBeforeSave={onBeforeSave}
      onSave={onSave}
      onStitch={onStitch}
      history={history}
      refetchCompanyQuery={refetchCompanyQuery}
      isFirstTime={isFirstTime}
    />
  )
}

VideosRecorder.propTypes = {
  company: PropTypes.object,
  topics: PropTypes.array,
  topic: PropTypes.object,
  path: PropTypes.string,
  previousTopicUrl: PropTypes.string,
  nextTopicUrl: PropTypes.string,
  practice: PropTypes.bool,
  saving: PropTypes.bool,
  renderPromptModal: PropTypes.func,
  onBeforeSave: PropTypes.func,
  onSave: PropTypes.func,
  onStitch: PropTypes.func,
  history: PropTypes.object,
  refetchCompanyQuery: PropTypes.func,
  isFirstTime: PropTypes.bool,
}

export default withRouter(VideosRecorder)
