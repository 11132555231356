import css from './index.module.sass'

import React, { useCallback, useContext, useRef } from 'react'
import { gql } from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Loader from '../Loader'
import Alert from '../Alert'
import ScheduleBar from '../ScheduleBar'

import FormLinker from './FormLinker'
import PrivacyBlock from './PrivacyBlock'
import ProgressBlock from './ProgressBlock'
import SharingBlock from './SharingBlock'
import KnowledgeCenterBlock from './KnowledgeCenterBlock'
import DevicesContext from '../DevicesContext'

import { GET_USER_COMPANY, UPDATE_COMPANY } from '../../constants/queries'
import { formatGraphQLError } from '../../helpers/errors'
import { formDataToServerData } from '../../helpers/forms'
import { moveItemInArray } from '../../helpers/arrays'
import PropTypes from 'prop-types'

const MOVE_COMPANY_MEMBER = gql`
  mutation moveCompanyMember($id: Int!, $position: Int!) {
    moveCompanyMember(input: { id: $id, position: $position }) {
      order
    }
  }
`

const PitchForm = ({ company, error, loading }) => {
  const { mobile } = useContext(DevicesContext)
  const formRef = useRef()

  const [
    updateCompany,
    { data: updatingData, loading: updating, error: updatingError },
  ] = useMutation(UPDATE_COMPANY, {
    update: (cache, { data: { updateCompany } }) => {
      const { company } = cache.readQuery({ query: GET_USER_COMPANY })

      cache.writeQuery({
        query: GET_USER_COMPANY,
        data: {
          company: {
            ...company,
            ...updateCompany.obj,
          },
        },
      })
    },
  })

  const [moveCompanyMember] = useMutation(MOVE_COMPANY_MEMBER)

  const handleUpdate = useCallback(
    (data) => {
      return updateCompany({
        variables: {
          input: {
            ...formDataToServerData(data, { urlKeys: ['website'] }),
            validate: false,
          },
        },
      })
    },
    [updateCompany]
  )

  const handleSectionSelect = useCallback((section) => {
    formRef.current.scrollToSection(section)
  }, [])

  const renderForm = () => {
    return (
      <div className={css.form}>
        <ScheduleBar variant="bottomOffset" />
        <FormLinker
          error={updatingError}
          formErrors={updatingData && updatingData.updateCompany.errors}
          company={company}
          saving={updating}
          formRef={formRef}
          onTeamReorder={(from, to, member) => {
            moveCompanyMember({
              variables: {
                id: member.id,
                position: to,
              },
              update: (
                cache,
                {
                  data: {
                    moveCompanyMember: { order },
                  },
                }
              ) => {
                const { company } = cache.readQuery({
                  query: GET_USER_COMPANY,
                })

                const nextMembers = order.map((id) =>
                  company.members.find((m) => m.id === id)
                )

                cache.writeQuery({
                  query: GET_USER_COMPANY,
                  data: {
                    company: {
                      ...company,
                      members: nextMembers,
                    },
                  },
                })
              },
              optimisticResponse: {
                __typename: 'Mutation',
                moveCompanyMember: {
                  __typename: '__CompanyMemberMoveMutationPayload',
                  order: moveItemInArray(
                    company.members.map((m) => m.id),
                    from,
                    to
                  ),
                },
              },
            })
          }}
          onUpdate={handleUpdate}
        />
      </div>
    )
  }

  if (loading) {
    return <Loader variant="centered" />
  }

  if (error) {
    return <Alert variant="error">{formatGraphQLError(error)}</Alert>
  }
  return (
    <div className={css.container}>
      <div className={css.sidebar}>
        <ProgressBlock company={company} onSelect={handleSectionSelect} />
        <div className={css.formContainer}>{mobile && renderForm()}</div>
        <PrivacyBlock company={company} />
        <SharingBlock />
        <KnowledgeCenterBlock />
      </div>

      {!mobile && renderForm()}
    </div>
  )
}

PitchForm.propTypes = {
  company: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.bool,
}

export default PitchForm
