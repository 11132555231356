import css from './index.module.sass'

import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'

import Ionicon from '../../Ionicon'
import List from '../../List'
import ReorderableRows from '../../ReorderableRows'

import Topic from './Topic'

import {
  DELETE_COMPANY_VIDEO_TOPIC,
  GET_TEMPLATE_TOPICS,
  GET_USER_COMPANY,
  MOVE_COMPANY_VIDEOTOPIC,
} from '../../../constants/queries'
import {
  CREATE_VIDEOS_NEW_CUSTOM_PATH,
  CREATE_VIDEOS_PATH,
} from '../../../constants/routes'
import { injectParams } from '../../../helpers/routes'
import { moveItemInArray } from '../../../helpers/arrays'
import Button from '../../Button'
import cloneDeep from 'lodash.clonedeep'
import Text from '../../Text'
import Link from '../../Link'
import CurrentUserContext from '../../CurrentUserContext'
import Popup from '../../Popup'
import { TOUR_VIDEO_BUILDER_TOPICS } from '../../../constants/tour'
import { durationToSeconds } from '../../../helpers/videos'
import { formatSecondsAsTime } from '../../../helpers/dates'

const Topics = ({
  topics,
  topic: currentTopic,
  hasNewTopic,
  practice,
  onNewTopicRemove,
  newTopic,
  removeCallBack,
  reorderCallBack,
  showUpgradeMessageCallBack,
  showingUpgradeMessage,
  onToggleShowTopics,
}) => {
  const history = useHistory()
  const {
    currentUser: { subscription },
  } = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription
    ? subscription.isPaidSubscription
    : false

  const {
    currentUser: { completedVideoBuilderTour },
  } = useContext(CurrentUserContext)
  // Whenever isFirstTime is set as string then it shouldn't show (it means user closed it) otherwise show it
  const [showFirstTime, setShowFirstTime] = useState(
    !localStorage.getItem('isFirstTime')
  )

  const [reorderingDisabled, setReorderingDisabled] = useState()

  const [moveCompanyVideoTopic] = useMutation(MOVE_COMPANY_VIDEOTOPIC, {
    onCompleted: () => {
      setReorderingDisabled(false)
      if (reorderCallBack) {
        reorderCallBack()
      }
    },
  })

  const [deleteCompanyVideoTopic, { loading: deleting }] = useMutation(
    DELETE_COMPANY_VIDEO_TOPIC,
    {
      update: (
        cache,
        {
          data: {
            deleteCompanyVideoTopic: { id },
          },
        }
      ) => {
        const { company } = cache.readQuery({ query: GET_USER_COMPANY })
        cache.writeQuery({
          query: GET_USER_COMPANY,
          data: {
            company: {
              ...company,
              videoTopics: company.videoTopics.filter((t) => t.id !== id),
            },
          },
        })
      },
      refetchQueries: [{ query: GET_TEMPLATE_TOPICS }],
      onCompleted: ({ deleteCompanyVideoTopic: { id } }) => {
        removeCallBack && removeCallBack(id)
      },
    }
  )

  const handleReorder = useCallback(
    (from, to, topic) => {
      setReorderingDisabled(true)

      moveCompanyVideoTopic({
        variables: {
          id: topic.id,
          position: to,
        },
        update: (
          cache,
          {
            data: {
              moveCompanyVideoTopic: { order },
            },
          }
        ) => {
          const { company } = cache.readQuery({ query: GET_USER_COMPANY })

          const nextVideoTopics = order.map((id) =>
            company.videoTopics.find((t) => t.id === id)
          )
          let mutableObjects = cloneDeep(company.concatenatedVideo)
          if (mutableObjects?.concatenatedVideo) {
            Object.assign(mutableObjects.concatenatedVideo, {
              videoIsUpToDate: false,
            })
          }

          cache.writeQuery({
            query: GET_USER_COMPANY,
            data: {
              company: {
                ...company,
                concatenatedVideo: mutableObjects,
                videoTopics: nextVideoTopics,
              },
            },
          })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          moveCompanyVideoTopic: {
            __typename: '__CompanyVideoTopicMoveMutationPayload',
            order: moveItemInArray(
              topics.map((t) => t.id),
              from,
              to
            ),
          },
        },
      })
    },
    [moveCompanyVideoTopic, topics]
  )

  const handleRemove = useCallback(
    (id) => {
      if (!id) {
        return onNewTopicRemove()
      } else {
        deleteCompanyVideoTopic({
          variables: {
            input: { id: id },
          },
        })
      }

      if (currentTopic.id === id) {
        const index = topics.findIndex((t) => t.id === id)

        if (index > 0) {
          const previousTopic = topics[index - 1]

          history.replace(
            injectParams(CREATE_VIDEOS_PATH, {
              topicId: previousTopic.id,
              subject: previousTopic.subject,
            })
          )
        } else {
          history.replace(injectParams(CREATE_VIDEOS_PATH))
        }
      }
    },
    [currentTopic, history, onNewTopicRemove, topics, deleteCompanyVideoTopic]
  )

  const handleClick = (evt) => {
    evt.preventDefault()
    if (newTopic) newTopic()
  }

  const handleNewCustom = () => {
    if (isPaidUserSubscription) {
      history.replace(injectParams(CREATE_VIDEOS_NEW_CUSTOM_PATH))
    } else {
      if (showUpgradeMessageCallBack) showUpgradeMessageCallBack()
    }
  }

  const totalDuration = () => {
    let totalDuration = 0
    topics.map((topic) => {
      const time = topic?.video?.duration || 0
      return (totalDuration += durationToSeconds(time))
    })
    return formatSecondsAsTime(totalDuration)
  }

  return (
    <div className={css.container}>
      <div className={css.list}>
        {!practice &&
          (hasNewTopic ? (
            <div className={css.newTopic}>
              <Topic onRemove={handleRemove} />
            </div>
          ) : (
            <>
              <div className={css.containerAddTemplateTopic}>
                <Button
                  variant="iconOnTopRoundPrimary"
                  icon={<Ionicon name="add" size="24" color="white" />}
                  className={css.btnAddTopic}
                  onClick={handleClick}
                />
                <Popup
                  visible={showFirstTime && completedVideoBuilderTour}
                  onCenter={false}
                  anchorSelector={`.${css.btnAddTopic}`}
                  variant="firstTime"
                  maxWidth={460}
                  header={
                    <div className={css.firstTimeHeader}>
                      <Text variant="h3" tag="h3">
                        Build your pitch video outline
                      </Text>
                    </div>
                  }
                  content={
                    <div className={css.firstTimeBody}>
                      <Text variant="standardLarger" offset="double-top">
                        Your pitch video will be created by stitching together a
                        series of topics. Choose from our list of templates or
                        add your own custom topics.
                      </Text>
                    </div>
                  }
                  footer={
                    <div className={css.firstTimeFooter}>
                      <Button
                        variant="primaryNarrow"
                        padding="double"
                        onClick={() => {
                          localStorage.setItem('isFirstTime', 'false')
                          setShowFirstTime(false)
                        }}
                      >
                        Got it
                      </Button>
                    </div>
                  }
                />

                <div className={css.subContainerAddTemplateTopic}>
                  <Text variant="standardLarger" offset="quarter-top">
                    Add topics from template
                  </Text>
                  <Link
                    onClick={handleNewCustom}
                    disabled={showingUpgradeMessage}
                    className={showingUpgradeMessage ? css.disabledLink : ''}
                  >
                    Or add custom topic
                  </Link>
                </div>
              </div>
            </>
          ))}
        <ReorderableRows
          items={topics}
          renderContainer={({ children }) => (
            <List customClass={TOUR_VIDEO_BUILDER_TOPICS}>{children}</List>
          )}
          renderItem={({ item, dragged, draggingDisabled, onDragStart }) => (
            <List.Item key={item.id}>
              <Topic
                topic={item}
                active={currentTopic === item}
                done={Boolean(item.video)}
                dragging={dragged}
                draggingDisabled={draggingDisabled}
                practice={practice}
                onDragStart={onDragStart}
                deleting={deleting}
                onRemove={handleRemove}
                onToggleShowTopics={onToggleShowTopics}
              />
            </List.Item>
          )}
          itemSelector="li"
          disabled={reorderingDisabled}
          onReorder={handleReorder}
        />
        <div className={css.containerTotalLength}>
          <Text className={css.totalLength}>
            {' '}
            Total video length: {totalDuration()}
          </Text>
        </div>
      </div>
    </div>
  )
}

Topics.propTypes = {
  topics: PropTypes.array,
  topic: PropTypes.object,
  hasNewTopic: PropTypes.bool,
  practice: PropTypes.bool,
  onNewTopicRemove: PropTypes.func,
  newTopic: PropTypes.func,
  removeCallBack: PropTypes.func,
  reorderCallBack: PropTypes.func,
  showUpgradeMessageCallBack: PropTypes.func,
  showingUpgradeMessage: PropTypes.bool,
  onToggleShowTopics: PropTypes.func,
}

export default Topics
